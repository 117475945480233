<template>
  <div>
    <div class="loader-spinner">Loading...</div>
  </div>
</template>

<script>
export default {
  name: "Spinner2"
}
</script>

<style scoped>
.loader-spinner {
  font-size: 10px;
  margin: 50px auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: #ffffff;
  background: -moz-linear-gradient(left, #8f8f8f 10%, rgba(255, 255, 255, 0) 42%);
  background: -webkit-linear-gradient(left, #8f8f8f 10%, rgba(255, 255, 255, 0) 42%);
  background: -o-linear-gradient(left, #8f8f8f 10%, rgba(255, 255, 255, 0) 42%);
  background: -ms-linear-gradient(left, #8f8f8f 10%, rgba(255, 255, 255, 0) 42%);
  background: linear-gradient(to right, #8f8f8f 10%, rgb(254 70 70 / 0%) 95%);
  position: relative;
  -webkit-animation: load3-data-v-1a2fd510 1.4s infinite linear;
  animation: load3-data-v-1a2fd510 1.4s infinite linear;
  transform: translateZ(0);
}
@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

</style>

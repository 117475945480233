<template>
  <div class="item">
    <div>
      <img :src="item.image" :alt="item.title" />
    </div>
    <div>
      <h4 v-text="item.title" />
      <p v-text="item.description" />
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutHomeItem",
  props: {
    item: Object
  }
}
</script>

<style scoped>
.item {
  display: flex;
}


.item *:first-child {
  margin-left: 5px;
}
@media screen and (max-width: 768px) {
  h4 {
    font-size: 1.2rem;
  }
}
</style>

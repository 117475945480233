<template>
  <div class="mb-sm-4 mb-2" v-if="loading">
    <div id="headerSkeletonInstagram">
      <b-skeleton type="input"></b-skeleton>
      <b-skeleton type="input"></b-skeleton>
      <br />
    </div>

    <b-container>
      <b-row>
        <b-col>
          <b-skeleton-img></b-skeleton-img>
        </b-col>
        <b-col>
          <b-skeleton-img></b-skeleton-img>
        </b-col>
        <b-col>
          <b-skeleton-img></b-skeleton-img>
        </b-col>
        <b-col>
          <b-skeleton-img></b-skeleton-img>
        </b-col>
      </b-row>
    </b-container>
  </div>
  <section v-else class="last-insta-posts">
    <div
      v-if="posts!=null"
      class="text-center mb-2 mb-sm-3"
    >
    <template v-if=" posts.instagram.length != 0">



      <h5 class="weight-bold text-color-444 fontsize-heavy">آخرین پست های اینستاگرام</h5>
      <p class="fontsize-small text-color-666 mb-1 mb-sm-2">
        ما را در اینستاگرام دنبال کنید تا از جدیدترین محصولات ما با خبر شوید
      </p>
    </template>
    </div>
    <div v-if="posts != null" class="container-fluid px-0 pt-2">
      <div
        v-if="posts.instagram.data.length != 0"
        class="swiper-container bg-color-ea h-100"
      >
        <div class="swiper-wrapper">
          <div
            v-for="item in posts.instagram.data"
            :key="item.id"
            class="swiper-slide bg-color-theme h-100 overflow-hidden"
          >
            <a :href="item.link" class="w-100 h-100">
              <div class="insta-posts-img">
                <img loading="lazy" :src="item.image.url" class="" alt="" />
              </div>
              <div class="insta-posts-hover p-3">
                <div class="text-center">
                  <span class="py-1 px-2 border-radius7">
                    <span class="ml-2">
                      <span class="text-white">{{ item.comments }}</span>
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M7.69524 13.3909C7.05048 13.3909 6.52667 12.8671 6.52667 12.2223C6.52667 11.5794 7.05048 11.0556 7.69524 11.0556C8.34001 11.0556 8.86382 11.5794 8.86382 12.2223C8.86382 12.8671 8.34001 13.3909 7.69524 13.3909ZM12.2381 13.3909C11.5933 13.3909 11.0695 12.8671 11.0695 12.2223C11.0695 11.5794 11.5933 11.0556 12.2381 11.0556C12.8829 11.0556 13.4067 11.5794 13.4067 12.2223C13.4067 12.8671 12.8829 13.3909 12.2381 13.3909ZM15.6125 12.2223C15.6125 12.8671 16.1363 13.3909 16.7811 13.3909C17.4258 13.3909 17.9496 12.8671 17.9496 12.2223C17.9496 11.5794 17.4258 11.0556 16.7811 11.0556C16.1363 11.0556 15.6125 11.5794 15.6125 12.2223Z"
                          fill="#fff"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M12.02 2C6.21 2 2 6.74612 2 12.015C2 13.6975 2.49 15.4291 3.35 17.0115C3.51 17.2729 3.53 17.6024 3.42 17.9139L2.75 20.1572C2.6 20.698 3.06 21.0976 3.57 20.9374L5.59 20.3375C6.14 20.1572 6.57 20.3866 7.08 20.698C8.54 21.5583 10.36 22 12 22C16.96 22 22 18.1642 22 11.985C22 6.65598 17.7 2 12.02 2Z"
                          stroke="#fff"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                    <span>
                      <span class="text-white">{{ item.likes }}</span>
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M11.761 20.8538C9.5904 19.5179 7.57111 17.9456 5.73929 16.1652C4.45144 14.8829 3.47101 13.3198 2.8731 11.5954C1.79714 8.25031 3.05393 4.42083 6.57112 3.28752C8.41961 2.69243 10.4384 3.03255 11.9961 4.20148C13.5543 3.03398 15.5725 2.69398 17.4211 3.28752C20.9383 4.42083 22.2041 8.25031 21.1281 11.5954C20.5302 13.3198 19.5498 14.8829 18.2619 16.1652C16.4301 17.9456 14.4108 19.5179 12.2402 20.8538L12.0051 21L11.761 20.8538Z"
                          stroke="#fff"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M15.7393 7.05301C16.8046 7.39331 17.5615 8.34971 17.6561 9.47499"
                          stroke="#fff"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  </span>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="swiper-button-next">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="currentColor"
            class="bi bi-chevron-compact-left"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M9.224 1.553a.5.5 0 0 1 .223.67L6.56 8l2.888 5.776a.5.5 0 1 1-.894.448l-3-6a.5.5 0 0 1 0-.448l3-6a.5.5 0 0 1 .67-.223z"
              fill="#999"
            />
          </svg>
        </div>
        <div class="swiper-button-prev">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="currentColor"
            class="bi bi-chevron-compact-right"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z"
              fill="#999"
            />
          </svg>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Swiper from "swiper/swiper-bundle.min";
import {samisize} from '@/libraries/VueMethods'
import {BSkeletonImg, BSkeleton, BContainer, BRow, BCol} from 'bootstrap-vue'

export default {
  name: "LastInstaPosts",
  components: {
    BSkeletonImg, BSkeleton, BContainer, BRow, BCol
  },
  beforeDestroy() {
    this.posts = [];
    window.removeEventListener("resize", this.onResize);
  },
  mounted() {
    // get height of instaImg/
    this.initHeaderSwiper();
    this.posts=this.$store.getters["front/getHomeData"];
    this.$store.commit('front/addHomeRetrievedEvent', () => {
      this.$nextTick(() => {
        document.querySelector('.last-insta-posts .swiper-slide img').addEventListener('load', () => {
          this.$nextTick(() => {
            setTimeout(() => {
              this.initHeaderSwiper(true);
              this.samisize()
            }, 200)
          })

        })
      })
    })
    window.removeEventListener("resize", this.onResize);
    window.addEventListener("resize", this.onResize);
  },
  computed: {
    // دریافت استاتوس تغییر وضعیت از ویو ایکس
    loading() {
      return this.$store.getters["front/getHomeDataStatus"];
    },
    // دریافت اطلاعات هوم دیتا
    homdeData(){
      return this.$store.getters["front/getHomeData"];
    }
  },
  watch: {
    posts() {
      this.initHeaderSwiper(true);
    },
    homdeData(val){
      this.posts=val;
    }
  },
  methods: {
    onResize() {
      this.samisize()
    },
    samisize() {
      setTimeout(() => {
        samisize('height', '.last-insta-posts .swiper-slide', '.last-insta-posts .swiper-wrapper', 120)
      }, 50)
    },
    initHeaderSwiper(kill = false) {
      this.initSwiper("instagram-swiper", kill, () => {
        try {
          if (this.slider) {
            this.slider.destroy()
          }
        }catch (e) {
          console.log(e)
        }

        this.slider = new Swiper(".last-insta-posts .swiper-container", {
          spaceBetween: 1,
          //   centeredSlides: true,
          loop: true,
          speed: 500,
          autoplay: {
            delay: 5000,
            disableOnInteraction: false,
          },
          navigation: {
            nextEl: ".last-insta-posts .swiper-button-next",
            prevEl: ".last-insta-posts .swiper-button-prev",
          },
          breakpoints: {
            250: {
              slidesPerView: 2,
              spaceBetween: 1,
            },
            500: {
              slidesPerView: 2,
              spaceBetween: 1,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 1,
            },

            992: {
              slidesPerView: 4,
              spaceBetween: 1,
            },
            1300: {
              slidesPerView: 6,
              spaceBetween: 1,
            },
          },
        });
      });
    },
  },
  data() {
    return {
      posts: this.$store.getters["front/getHomeData"],
    };
  },
};
</script>

<style scoped>
#headerSkeletonInstagram {
  width: 50%;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

</style>

<template>
  <div class="service">
    <div class="services__box">
      <div class="services__support">
        <p><Supported /></p>
        <p class="service__title" >
          7 روز مهلت تست
        </p>
        <p class="service__desc" > درکلیه سفارشات بالای 200.000 هزار تومان </p>
      </div>
      <div class="services__payment">
        <p><Payment /></p>
        <p class="service__title" >
          پرداخت امن
        </p>
        <p class="service__desc" > درکلیه سفارشات بالای 1.000.000 هزار تومان </p>
      </div>
      <div class="services__delivery">
        <p><Delivery /></p>
        <p class="service__title" >
          حمل و نقل رایگان
        </p>
        <p class="service__desc" > درکلیه سفارشات بالای 1.000.000 هزار تومان </p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.service {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 4rem 0;
}

.services__box {
  width: 85%;
  display: flex;
  justify-content: space-around;
  text-align: center;
  flex-wrap: wrap;
  line-height: 2.5;
}
.services__support {

  flex-grow: 1;
}
.services__payment {

  flex-grow: 1;
}
.services__delivery {

  flex-grow: 1;
}

.service__title  {
  font-size: 20px;
}
.service__desc {
  color: gray;
}

</style>

<script>
import Supported from "@/parts/Front/components/shared/Supported";
import Payment from "@/parts/Front/components/shared/Payment";
import Delivery from "@/parts/Front/components/shared/Delivery";
export default {
  components: {
    Delivery,
    Payment,
    Supported
  },
};
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="15.211" height="21.039" viewBox="0 0 20.211 21.039">
    <g id="Icon_feather-arrow-left" data-name="Icon feather-arrow-left" transform="translate(-6.5 -6.086)">
      <path id="Path_2004" data-name="Path 2004" d="M25.711,18H7.5" transform="translate(0 -1.395)" fill="none" :stroke="fill" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      <path id="Path_2005" data-name="Path 2005" d="M16.605,25.711,7.5,16.605,16.605,7.5" fill="none" :stroke="fill" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: "ArrowLeft",
  props: {
    fill: {
      default: '#fff',
      type: String
    }
  }
}
</script>

<style scoped>

</style>

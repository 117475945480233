<template>
  <DynamicLinker v-if="mediaQueries.mobileSize && mobileItem && mobileItem.picture" :item="mobileItem">
    <img :src="mobileItem.picture" class="w-100" alt="banner">
  </DynamicLinker>
  <DynamicLinker v-else-if="item && item.picture" :item="item">
    <img :src="item.picture" class="w-100" alt="banner">
  </DynamicLinker>

</template>

<script>
import DynamicLinker from "@/parts/Front/components/shared/DynamicLinker";
export default {
  name: "ResponsiveBanner",
  components: {DynamicLinker},
  inject: ['mediaQueries'],
  props: {
    item: Object,
    mobileItem: Object
  }
}
</script>

<style scoped>

</style>

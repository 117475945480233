<template>
  <router-link
      :to="to"
      class="recommended-products-more fontsize-mini text-black px-3 py-3 mt-3 d-inline-block"
  >
    <span :class="{'ml-3': !mediaQueries.mobile}">مشاهده همه محصولات</span>
  <ArrowLeft class="icon"/>
  </router-link>
</template>

<script>
import ArrowLeft from "@/parts/Front/nicecomponents/svgs/ArrowLeft";
export default {
  name: "MoreButton",
  inject: ['mediaQueries'],
  components: {ArrowLeft},
  props: {
    to: String
  }
}
</script>

<style scoped>
.icon {
  transition: 200ms all;
}
.recommended-products-more:hover .icon {
  transform: translateX(3px);
}
.recommended-products-more {
  background-color: var(--color-theme);
  color: white;
}

.recommended-products-more:hover {
  background: #000;
  color: white;
}

</style>

<template>
    <treeselect
        :searchable="!mediaQueries.mobileSize"
        :clearable="false"
        :options="options"
        noChildrenText="فرزندی وجود ندارد" noOptionsText="موردی وجود ندارد" noResultsText="موردی یافت نشد"
        placeholder="تعداد"
        v-model="product.wanted_quantity"
    />
</template>

<script>
import {Treeselect} from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  inject:['mediaQueries'],
  name: "ProductSelectQuantity",
  props: {
    value: Object,
  },
  data() {
    return {
      product: this.value
    }
  },
  watch: {
    value(newVal) {
      this.product = newVal;
    },
    product(newVal) {
      this.$emit('input', newVal)
    },
  },
  components: {Treeselect},
  computed: {
    options() {
      let options = [];
      let max = Math.min(this.product.getMaxQuantity(), 300);
      for (let i =1 ; i <= max; i++) {
        options.push({
          id: i,
          label: i
        })
      }

      return options;
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <div @mouseleave="$emit('left')" class="c-compare-footer__content">
    <div class="c-compare-footer__items js-compare-footer-content js-compare-container">
      <div v-for="product in compares" :key="product.id" class="js-compare-list-product">
        <div class="c-compare-footer__product-item">
          <div class="c-compare-footer__product-image"><img
              :src="getImage(product.major_image, 'lg')"
              class="js-compare-product-image"
              :alt="product.title"></div>
          <div class="c-compare-footer__product-title js-compare-product-title">{{ product.title }}</div>
          <button @click="$store.commit('front/removeCompare', {product, $root})" class="c-compare-footer__remove-item js-product-remove">
            <span >x</span>
          </button>
        </div>
      </div>
    </div>
    <div @click="removeAll" class="c-compare-footer__actions">
      <div class="c-compare-footer__cancel js-compare-all-remove">انصراف</div>
    </div>
  </div>
</template>

<script>
import {getImage} from "@/libraries/functions";

export default {
  name: "CompareContent",
  methods: {
    getImage,
    removeAll() {
      this.$store.commit('front/resetCompare')
      this.$emit('left')
    }
  },
  computed: {
    compares() {
      return this.$store.getters['front/getCompares'];
    }
  }
}
</script>

<style scoped>
button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring {
  outline: 1px dotted ButtonText;
}

img, img[alt] {
  line-height: 22px;
}


.c-compare-footer:hover .c-compare-footer__content {
  display: block;
}

.c-compare-footer__content {
  width: 235px;
  background-color: #fff;
  border: 1px solid #eaeaea;
  -webkit-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, .16);
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, .16);
  position: fixed;
  left: 20px;
  bottom: 64px;
  z-index: 2;
  border-radius: 5px;
}

.c-compare-footer__actions {
  height: 73px;
  width: 100%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 15px 20px 20px 10px;
  border-top: 1px solid #ebebeb;
}

.c-compare-footer__cancel {
  font-size: 14px;
  line-height: 25px;
  color: #fb3449;
  cursor: pointer;
}

.c-compare-footer__items {
  padding: 0 12px;
}

.c-compare-footer__product-item {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 100%;
  position: relative;
  padding: 5px 0;
  border-bottom: 1px solid #ebebeb;
}

.c-compare-footer__product-item:last-of-type {
  border-bottom: none;
}

.c-compare-footer__remove-item {
  position: absolute;
  cursor: pointer;
  width: 18px;
  height: 18px;
  background-color: #e3e3e3;
  border: none;
  border-radius: 100%;
  top: 5px;
  left: 0;
  line-height: 21px;
}

.c-compare-footer__remove-item span {
  transform: rotate(
      90deg
  ) translate(-2px, 0px);
  color: #9d9d9d;
}

.c-compare-footer__product-image {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 65px;
  flex: 0 0 65px;
  width: 65px;
  height: 65px;
  background-color: #fff;
  position: relative;
}

.c-compare-footer__product-image img {
  overflow: hidden;
}

.c-compare-footer__product-image img[alt]:after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-weight: 300;
  color: transparent;
  text-align: center;
  content: attr(alt);
  background: #fff url(https://www.digikala.com/static/files/f8c6572e.svg) no-repeat 50%;
  background-size: contain;
  width: 100%;
  height: 100%;
}

.c-compare-footer__product-image img {
  max-width: 100%;
  max-height: 100%;
}

.c-compare-footer__product-title {
  padding-right: 4px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(100% - 65px);
  flex: 0 0 calc(100% - 65px);
  max-width: calc(100% - 65px);
  font-size: 10px;
  font-size: .714rem;
  line-height: 17px;
  color: #535353;
  text-align: right;
  direction: rtl;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

</style>

<template>
    <div class="swiper-slide bg-white">
        <router-link :to="product != null ? {name: 'product.detail' , params: {id: product.id , slug:product.slug}} : ''" >
<!--            <template v-if="product && sortVarieties[0].final_price.discount_type && product.status == 'available' ">-->
<!--                <DiscountTag style="top: 5px" :variety="sortVarieties[0]" />-->
<!--            </template>-->
            <div class="campaigns-slide-img">
                <img loading="lazy" v-if="product && mainImage" class="" :src="getImage(mainImage, 'lg')" :alt="product.title">
            </div>
            <div class="campaigns-slide-bottom text-center d-flex flex-column justify-content-end">
                <h6 class="weight-bold text-color-444 fontsize-medium mb-0">
                    {{ product.title.substr(0, 33) }}
                    {{ product.title.length > 33 ? '...' : '' }}
                </h6>
                <div class="mb-3">
                    <span
                        class="fontsize-medium ml-1 weight-bold text-color-themeRed">{{mainPrice | price}}</span><span
                        class="fontsize-mini text-color-999">تومان</span>
                    <span v-if="discountAmount != 0" class="pr-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" fill="currentColor"
                            class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                            <path fill="#aaa" fill-rule="evenodd"
                                d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z" />
                            <path fill="#aaa" fill-rule="evenodd"
                                d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                        <span
                            class="fontsize-small line-through mr-1 text-color-666">{{(mainPrice + discountAmount) | price}}</span>
                    </span>
                </div>
            </div>
        </router-link>
    </div>
</template>
<script>
    import {
        getImages,
        sortVarieties,
        getImage
    } from '@/libraries/functions'
    import DiscountTag from "../product/DiscountTag";
    export default {
        name: 'FlashProduct',
      components: {DiscountTag},
      props: ['product'],
      computed: {
        mainPrice() {
          return this.product ? this.product.price : null
        },
        discountAmount() {
          return this.product ? this.product.major_discount_amount : null
        },
        mainImage() {
          return this.product ? this.product.major_image : null
        }
      },
    }
</script>
<style scoped>

</style>

<template>
  <DynamicLinker :item="item">
    <img :src="item.picture" class="w-100" alt="banner">
  </DynamicLinker>
</template>

<script>
import DynamicLinker from "@/parts/Front/components/shared/DynamicLinker";
export default {
  name: "SingleBanner",
  components: {DynamicLinker},
  props: {
    item: Object
  }
}
</script>

<style scoped>

</style>

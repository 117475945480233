<template>
  <svg fill="#fff" xmlns="http://www.w3.org/2000/svg" width="14.619" height="24.746" viewBox="0 0 14.619 24.746">
    <path id="Icon_ionic-ios-arrow-forward" data-name="Icon ionic-ios-arrow-forward" d="M20.679,18,11.742,9.07a1.681,1.681,0,0,1,0-2.384,1.7,1.7,0,0,1,2.391,0L24.258,16.8a1.685,1.685,0,0,1,.049,2.327L14.14,29.32a1.688,1.688,0,0,1-2.391-2.384Z" transform="translate(-10.746 -5.566)" stroke="#777" stroke-width="1"/>
  </svg>
</template>

<script>
export default {
  name: "ZArrowBold"
}
</script>

<style scoped>

</style>
